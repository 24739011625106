@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Courier+Prime:ital,wght@0,400;0,700;1,400;1,700&family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@layer base {
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

* {
  font-family:
    'Inter',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.ant-message-notice.ant-message-notice-success .ant-message-notice-content {
  @apply w-[600px] rounded-xl bg-neutral-950 text-left font-normal shadow-card;
}

.ant-message-notice.ant-message-notice-error .ant-message-notice-content {
  @apply w-[600px] rounded-xl bg-red-600 text-left font-normal shadow-card;
}

/* .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid #ececec !important;
} */

.ant-input-number-group-addon {
  border-color: #b8b8b8 !important;
  border-left: none !important;
}

.hide-right-border .ant-input-number {
  border-right: none !important;
}

.ant-input-number-input {
  padding: 0 16px !important;
}

.ant-color-picker-color-block-inner {
  border-radius: 50% !important;
}

.ant-color-picker-trigger {
  border-radius: 50%;
}

.ant-upload.ant-upload-btn {
  padding: 0 !important;
}

.default-modal > div > div {
  max-width: 100% !important;
}

.custom-modal-index > div {
  z-index: 1100 !important;
}

.upload-image-modal .ant-upload.ant-upload-drag {
  border: none !important;
  background-color: transparent !important;
}

.upload-image-modal:has(.ant-upload-list-item-container) .ant-upload.ant-upload-drag {
  display: none !important;
}

/* https://kovart.github.io/dashed-border-generator/ */
.upload-image-modal .dragger-container {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='%23B8B8B8FF' stroke-width='1' stroke-dasharray='10%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 16px;
}

.analytics-contact-select .ant-select-selection-item {
  padding-inline-end: 24px !important;
}

.horizon-table-styling {
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.04);
}

.horizon-table-styling table {
  width: 100%;
  display: table;
}

.horizon-table-styling > div > div {
  border: none !important;
}

.checker-hover,
.checker-action-hover,
.checker-commented {
  /* @apply cursor-pointer !bg-blue-100; */
  @apply relative z-0 cursor-pointer overflow-hidden before:absolute before:-bottom-[2px] before:-left-1 before:-right-1 before:-top-[2px] before:z-[-1] before:!rounded before:border before:border-solid before:border-blue-100 before:bg-blue-100/70 before:content-[''];
}

.checker-bubble {
  @apply absolute -bottom-4 -left-2 z-10 flex h-10 w-10 cursor-pointer select-none items-center justify-center;
}

.checker-popover .ant-popover-inner {
  @apply border border-solid border-blue-200 bg-blue-50;
}

.checker-popover .ant-popover-title:not(:has(div)) {
  @apply m-0;
}

.ant-popover-inner:has(.EmojiPickerReact) {
  @apply !p-0;
}

.EmojiPickerReact {
  @apply !border-none;
}

.custom-selectmenu > button {
  margin-left: auto !important;
  border-color: #d3d6db !important;
  height: 41px !important;
  padding: 2px 2px 2px 16px !important;
  box-shadow: none !important;
  border-style: solid !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  display: inline-block !important;
}

.custom-selectmenu > button > div > div {
  overflow-x: hidden !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  white-space: nowrap !important;
}

.custom-selectmenu:hover[data-disabled='false'] > button {
  border-color: #5f5f5f !important;
  height: 41px !important;
  padding: 2px 2px 2px 16px !important;
  box-shadow: none !important;
  border-style: solid !important;
}

.custom-selectmenu[data-disabled='true'] > button {
  background-color: rgba(0, 0, 0, 0.05) !important;
  height: 41px !important;
  padding: 2px 2px 2px 16px !important;
  box-shadow: none !important;
  opacity: 0.5 !important;
  cursor: not-allowed !important;
  border-color: #b3b3b3 !important;
  border-style: solid !important;
}

.custom-selectmenu-inside-input > button {
  border-color: #bac3d1 !important;
  background-color: transparent !important;
  height: 32px !important;
  padding: 0 5px !important;
  box-shadow: none !important;
}

.custom-selectmenu-inside-input > button > div > div {
  border-color: #bac3d1 !important;
  background-color: transparent !important;
  height: 32px !important;
  width: auto !important;
  padding: 0 5px !important;
  box-shadow: none !important;
}

.custom-dropdown-menu {
  z-index: 10000 !important;
  position: relative !important;
}
